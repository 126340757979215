import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Image, Input, Row, Space, Table } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getPartnerFlg, Partner } from 'src/domain/partnerType';
import PageHeader from 'src/layouts/PageHeader';
import locationServices from 'src/services/locationServices';
import partnerServices from 'src/services/partnerServices';
import { currDate, toDayjs } from 'src/utils/dateUtils';
import excelImg from "src/views/images/excel.png";
import { utils, writeFileXLSX } from 'xlsx';
import Section from '../../components/container/Section';
import PartnerDrawer from './drawer/PartnerDrawer';

const PartnerList = () => {
    const [searchName, setSearchName] = useState<any>("");
    const [page, setPage] = React.useState(1);
    const [partners, setPartners] = useState<any[]>([]);
    const [isShowDrawer, setIsShowDrawer] = useState(false);

    const columnSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            align: "center" as AlignType, width: 60,
            render: (value: number, item: Partner, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "partnerCode",
            key: "partnerCode",
            sorter: (a: any, b: any) => columnSorter(a.id, b.id),
        },
        {
            title: "ชื่อ",
            dataIndex: "partnerName",
            key: "partnerName",
            sorter: (a: any, b: any) => columnSorter(a.id, b.id),
            render: (value: string, item: Partner, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Link to={`/app/partner/view/${item.id}`}>{value}</Link>
                </Col>
            )
        },
        {
            title: "เบอร์โทร",
            dataIndex: "partnerTel",
            key: "partnerTel",
            sorter: (a: any, b: any) => columnSorter(a.id, b.id),
        },
        {
            title: "ที่อยู่",
            dataIndex: "address",
            key: "address",
            sorter: (a: any, b: any) => columnSorter(a.id, b.id),
            render: (value: any, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex' }}>
                    <span>{value} {item.location}</span>
                </Col>
            )
        },
        {
            title: "สถานะ",
            dataIndex: "useFlg",
            key: "useFlg",
            sorter: (a: any, b: any) => columnSorter(a.id, b.id),
            render: (value: any, item: any, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'center' }}>
                    <span>{getPartnerFlg(value)}</span>
                </Col>
            )
        },
    ];

    const changeSearchText = (searchText: any) => {
        setSearchName(searchText);
    }

    const search = async () => {
        const res = await getByPartnerName(searchName);
        if (res && res.length > 0) {
            for (let x of res) {
                x.location = await getAddressPath(x.locationCode);
            }
        }
        setPartners(res);
    }

    async function getByPartnerName(name: string): Promise<any[]> {
        const res = await partnerServices.getByPartnerName(searchName);
        if (res.data) {
            return res.data;
        } else {
            return [];
        }
    }

    async function getAddressPath(locationCode: string): Promise<string> {
        const res = await locationServices.getByTambonCode(locationCode);
        if (res.data) {
            return res.data.addrPath;
        } else {
            return '';
        }
    }

    const onDownloadExcel = () => {
        const header = ['ลำดับ', 'รหัส', 'ชื่อ', 'เบอร์โทร', 'ที่อยู่', 'สถานะ'];
        let data = [];
        if (partners.length > 0) {
            for (let i = 0; i < partners.length; i++) {
                data.push({
                    'ลำดับ': i + 1,
                    'รหัส': partners[i].partnerCode,
                    'ชื่อ': partners[i].partnerName,
                    'เบอร์โทร': partners[i].partnerTel,
                    'ที่อยู่': partners[i].address + ' ' + partners[i].location,
                    'สถานะ': getPartnerFlg(partners[i].useFlg)
                })
            }
        }
        const ws = utils.json_to_sheet(data, { header: header });
        var wscols = [{ wch: 6 }, { wch: 20 }, { wch: 30 }, { wch: 15 }, { wch: 50 }, { wch: 10 }];
        ws['!cols'] = wscols; // column width
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'report');
        writeFileXLSX(wb, `${'partner_' + currDate()}.xlsx`);
    }

    const onOpenDrawer = () => {
        setIsShowDrawer(true);
    }

    const onCloseDrawerWithRefresh = (val: boolean) => {
        setIsShowDrawer(false);
        if (val === true) {
            search();
        }
    }

    return (
        <div>
            <PageHeader title="กำหนดพาร์ทเนอร์" subTitle="เพิ่ม แก้ไข เปลี่ยนแปลงข้อมูลพาร์ทเนอร์">
                <Space size={'large'}>
                    <Button
                        onClick={onDownloadExcel}
                        style={{ width: 120 }}
                    >
                        ส่งออก
                        <Image
                            width={30} height={30}
                            src={excelImg}
                            preview={false}
                        />
                    </Button>
                    <Button
                        size="large"
                        style={{ width: 120 }}
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={onOpenDrawer}
                    >
                        เพิ่ม
                    </Button>
                </Space>
            </PageHeader>

            <Section>
                <Form layout="vertical">
                    <Row style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Col span={12}>
                            <Form.Item label="ชื่อพาร์ทเนอร์">
                                <Input allowClear
                                    style={{
                                        borderRadius: "5px",
                                        width: "300px",
                                    }}
                                    name="searchName"
                                    onChange={(e) => changeSearchText(e.target.value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                icon={<SearchOutlined />}
                                onClick={() => search()}
                                style={{ marginTop: 30 }}>
                                ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Section>

            <Section style={{ marginTop: 30 }}>
                <Table
                    columns={columns}
                    dataSource={partners}
                    pagination={{
                        pageSize: 10,
                        onChange(current) {
                            setPage(current);
                        },
                    }}
                    rowKey={(item: Partner) => item.id}
                    size="small"
                />
            </Section>

            <Drawer
                title="ข้อมูลพาร์ทเนอร์"
                width="400px"
                open={isShowDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}

            >
                <PartnerDrawer
                    partner={undefined}
                    saveComplete={() => onCloseDrawerWithRefresh(true)}
                    onCloseDrawer={() => onCloseDrawerWithRefresh(false)}
                />
            </Drawer>
        </div >
    )
}

export default PartnerList
