import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + '/partnerServiceArea';

const getRowKeysByFilter = (partnerCode: String, stationCode: String, locationCode: String) => {
    const param = `partnerCode=${partnerCode}&stationCode=${stationCode}&locationCode=${locationCode}`;
    return http.get(url + "/rowKeysByFilter/?" + param);
}

const addPartnerServiceArea = (entity: any) => {
    return http.post(url + "/add", entity);
};

const addAllPartnerServiceArea = (list: any) => {
    return http.put(url + "/addAll", list);
};

const deletePartnerServiceArea = (entity: any) => {
    return http.put(url + "/delete", entity);
};

const deleteAllPartnerServiceArea = (entity: any) => {
    return http.put(url + "/deleteAll", entity);
};

export default {
    getRowKeysByFilter,
    addPartnerServiceArea,
    addAllPartnerServiceArea,
    deletePartnerServiceArea,
    deleteAllPartnerServiceArea
}
