import { Col, Input, message, Row, Table, Tabs, Typography } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Section from 'src/components/container/Section';
import { StationArea } from 'src/domain/stationAreaType';
import { Station } from 'src/domain/stationType';
import stationAreaServices from 'src/services/stationAreaServices';
import stationServices from 'src/services/stationServices';
import { SessionUser } from 'src/store/authen/types';
import { showMessage } from 'src/utils';
import partnerServiceAreaServices from 'src/services/partnerServiceAreaServices';

interface IProps {
    partner: any;
}

const PartnerServiceAreaInfo: React.FC<IProps> = (props) => {
    const { partner } = props;
    const [stations, setStations] = useState<Station[]>([]);
    const [filterStation, setFilterStation] = useState<Station[]>([]);
    const [areas, setAreas] = useState<StationArea[]>([]);
    const [filterArea, setFilterArea] = useState<StationArea[]>([]);
    const [selectStation, setSelectStation] = useState('');
    const [isLoad, setLoad] = useState(false);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);
    const [page, setPage] = React.useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);

    const columnSorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    const lineColumns = [
        {
            title: "ลำดับ", dataIndex: "index", key: "index",
            align: "center" as AlignType,
            render: (value: number, item: any, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "Location", dataIndex: "locationFullName", key: "locationFullName",
            align: "center" as AlignType,
            sorter: (a: any, b: any) => columnSorter(a.locationFullName, b.locationFullName),
            render: (value: string, item: StationArea, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
        {
            title: "รหัสสาย", dataIndex: "lineCode", key: "lineCode",
            align: "center" as AlignType,
            render: (value: string, item: StationArea, index: number) => (
                <Col span={22} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <span>{value}</span>
                </Col>
            )
        },
    ];

    useEffect(() => {
        if (!isLoad) {
            handleSearchStation('');
            setLoad(true);
        }
    }, []);

    useEffect(() => {
        if (selectStation) {
            fetchSelectedRowKeys(selectStation);
        }
    }, [selectStation]);

    async function handleSearchStation(val: string) {
        const res = await stationServices.getByParam('', val, '', '', 0)
        if (res.status === 200) {
            const sort = await res.data.sort(function (a: any, b: any) {
                let x = a.stationName.toLowerCase();
                let y = b.stationName.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            setStations(await sort)
            setFilterStation(await sort)
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function handleSearchStationArea(val: string) {
        setAreas([])
        setFilterArea([])
        const res = await stationAreaServices.getLinesByStationCode(val, 0);
        console.log('getByStationCode', res.data)
        if (res.status === 200) {
            const sort = await res.data.sort(function (a: any, b: any) {
                let x = a.lineCode.toLowerCase();
                let y = b.lineCode.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            setAreas(await sort);
            setFilterArea(await sort);
            setSelectStation(val);
        } else {
            showMessage('Error status : ' + res.status + ' ' + res.statusText);
        }
    }

    async function fetchSelectedRowKeys(val: string) {
        const res = await partnerServiceAreaServices.getRowKeysByFilter(partner.partnerCode, val, '');
        if (res.status === 200) {
            if (res.data.length > 0) {
                setSelectedRowKeys(res.data);
            } else {
                setSelectedRowKeys([]);
            }
        }
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // setSelectedRowKeys(newSelectedRowKeys);
    };

    function onSelectRow(record: any, selected: boolean) {
        const data = {
            partnerCode: partner.partnerCode,
            stationCode: record.stationCode,
            locationCode: record.locationCode,
            lineCode: record.lineCode,
            lineName: record.lineName,
            savStation: sessionUser.stationCode,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username
        };

        console.log("onSelectRow ", data);
        if (selected) {
            try {
                partnerServiceAreaServices.addPartnerServiceArea(data).then(() => {
                    message.success("ใช้งาน " + record.lineCode, 1.5);
                    fetchSelectedRowKeys(selectStation);
                }).catch((err: any) => {
                    console.log(err);
                });
            } catch (err) {
                showMessage('' + err);
            }
        } else {
            try {
                partnerServiceAreaServices.deletePartnerServiceArea(data).then(() => {
                    message.success("ยกเลิกใช้งาน " + record.lineCode, 1.5);
                    fetchSelectedRowKeys(selectStation);
                }).catch((err: any) => {
                    console.log(err);
                });
            } catch (err) {
                showMessage('' + err);
            }
        }
    };

    function onSelectAllRow(selected: boolean) {
        setLoading(true);
        const data = {
            partnerCode: partner.partnerCode,
            stationCode: selectStation,
            savStation: sessionUser.stationCode,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username
        };

        console.log("onSelectAllRow ", data);
        if (selected) {
            try {
                partnerServiceAreaServices.addAllPartnerServiceArea(data).then(() => {
                    message.success("ใช้งานทั้งหมด ", 1.5);
                    fetchSelectedRowKeys(selectStation);
                    setLoading(false);
                }).catch((err: any) => {
                    console.log(err);
                    setLoading(false);
                });
            } catch (err) {
                showMessage('' + err);
                setLoading(false);
            }
        } else {
            try {
                partnerServiceAreaServices.deleteAllPartnerServiceArea(data).then(() => {
                    message.success("ยกเลิกใช้งานทั้งหมด ", 1.5);
                    fetchSelectedRowKeys(selectStation);
                    setLoading(false);
                }).catch((err: any) => {
                    console.log(err);
                    setLoading(false);
                });
            } catch (err) {
                showMessage('' + err);
                setLoading(false);
            }
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        onSelect: (record: any, selected: any) => { onSelectRow(record, selected); },
        onSelectAll: (selected: any) => { onSelectAllRow(selected); },
    };

    async function handleFilterStation(val: string) {
        const station = stations.filter((item) => item.stationName.includes(val))
        setFilterStation(station);
    }

    async function handleFilterLine(val: string) {
        const area = areas.filter((item) => item.locationFullName.includes(val))
        setFilterArea(area);
    }

    return (
        <div>
            <Section>
                <Row>
                    <Col span={6}>
                        <Row style={{ alignItems: 'baseline' }}>
                            <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                                <Typography.Text>สาขา :</Typography.Text>
                            </Col>
                            <Col span={16} push={0}>
                                <Input onChange={(e) => handleFilterStation(e.target.value)} />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={18}>
                        <Row style={{ alignItems: 'baseline' }}>
                            <Col span={2} style={{ textAlign: "right", paddingRight: "5px", }} >
                                <Typography.Text>ตำบล :</Typography.Text>
                            </Col>
                            <Col span={6} push={0}>
                                <Input onChange={(e) => handleFilterLine(e.target.value)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={24} style={{ paddingTop: 10, }}>
                        <Tabs tabPosition={'left'} size={'small'}
                            defaultActiveKey={selectStation}
                            tabBarStyle={{ width: '25%', paddingLeft: 30 }}
                            onTabClick={(key: any) => {
                                handleSearchStationArea(key);
                            }}
                            items={filterStation.map((item: any, i) => {
                                return {
                                    label: item.stationName,
                                    key: item.stationCode,
                                    children: (
                                        <Col span={24}>
                                            <Row>
                                                <Col span={24} style={{ paddingTop: 10 }}>
                                                    <Table
                                                        rowSelection={rowSelection}
                                                        size='middle'
                                                        dataSource={filterArea}
                                                        columns={lineColumns}
                                                        rowKey={(item: StationArea) => item.locationCode}
                                                        pagination={{
                                                            pageSize: 10,
                                                            onChange(current) {
                                                                setPage(current);
                                                            },
                                                        }}
                                                        loading={loading}
                                                    // pagination={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                };
                            })}
                        />
                    </Col>
                </Row>
            </Section>
        </div>
    )
}

export default PartnerServiceAreaInfo
