import { Button, Col, Row, Typography, Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import Section from 'src/components/container/Section'
import { blue } from '@ant-design/colors';
import { CloseCircleOutlined, EditFilled } from '@ant-design/icons';
import PartnerDrawer from './drawer/PartnerDrawer';
import locationServices from 'src/services/locationServices';

interface IProps {
    partner: any;
    refresh: any;
}

const PartnerInfo: React.FC<IProps> = (props) => {
    const { partner, refresh } = props;
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [location, setLocation] = useState({} as any);

    useEffect(() => {
        fetchLocation(partner.locationCode);
    }, [partner.locationCode]);

    const fetchLocation = async (locationCode: any) => {
        const res = await locationServices.getByTambonCode(locationCode);
        setLocation(res.data)
    }

    const onOpenDrawer = () => {
        setIsShowDrawer(true);
    }

    const onCloseDrawerWithRefresh = (val: boolean) => {
        setIsShowDrawer(false);
        if (val === true) {
            refresh();
        }
    }

    return (
        <div>
            <Section>
                <Row gutter={16} justify="space-between">
                    <Col span={20} style={{ display: "flex", justifyContent: "flex-start", alignItems: 'flex-start' }}>
                        <Typography.Text strong>ข้อมูลพาร์ทเนอร์</Typography.Text>
                    </Col>
                    <Col span={4} style={{ display: "flex", justifyContent: "flex-end", alignItems: 'flex-start' }}>
                        <Button shape="circle" size='middle' icon={<EditFilled style={{ color: blue.primary }} />} onClick={onOpenDrawer} />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}  >
                        <Typography.Text>#{partner.partnerCode}</Typography.Text>
                    </Col>
                    <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                        <Typography.Text>ชื่อ :</Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Typography>{partner.partnerName}</Typography>
                    </Col>
                    <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                        <Typography.Text>เบอร์โทร :</Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Typography>{partner.partnerTel}</Typography>
                    </Col>
                    <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                        <Typography.Text>TAX ID :</Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Typography>{partner.taxId}</Typography>
                    </Col>
                    <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                        <Typography.Text>เลขที่ :</Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Typography>{partner.address}</Typography>
                    </Col>
                    <Col span={6} style={{ textAlign: "right", paddingRight: "5px", }} >
                        <Typography.Text>ที่อยู่ :</Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Typography>{location.tambon}</Typography>
                        <Typography>{location.amphoe}</Typography>
                        <Typography>{location.province}</Typography>
                        <Typography>{location.zipcode}</Typography>
                    </Col>
                </Row>
            </Section>

            <Drawer
                title="ข้อมูลพาร์ทเนอร์"
                width="400px"
                onClose={() => onCloseDrawerWithRefresh(false)}
                open={isShowDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <PartnerDrawer
                    partner={partner}
                    saveComplete={() => onCloseDrawerWithRefresh(true)}
                    onCloseDrawer={() => onCloseDrawerWithRefresh(false)}
                />
            </Drawer>
        </div>
    )
}

export default PartnerInfo
