import { PartnerServiceArea } from "./partnerServiceAreaType";

export interface Partner {
    id: number;
    partnerCode: string | undefined;
    prenameId: number | undefined;
    partnerName: string | undefined;
    address: string | undefined;
    addrPath: string | undefined;
    locationCode: string | undefined;
    zipcode: string | undefined;
    taxId: string | undefined;
    partnerTel: string | undefined;
    useFlg: number | undefined;
    savStation: string | undefined;
    createUserid: number | undefined;
    createUsername: string | undefined;
    partnerServiceAreas: PartnerServiceArea[] | undefined;
}

export const getPartnerFlg = (useFlg: number) => {
    if (isNaN(useFlg)) {
        return '-';
    }
    if (useFlg === 0) {
        return 'ใช้งาน';
    } else if (useFlg === -1) {
        return 'ไม่ใช้งาน';
    }
    return '-';
}