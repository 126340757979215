import { blue } from '@ant-design/colors';
import { CloseCircleOutlined, EditFilled } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Col, Drawer, Dropdown, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Partner } from 'src/domain/partnerType';
import PageHeader from 'src/layouts/PageHeader';
import filepriceServices from 'src/services/filepriceServices';
import partnerServices from 'src/services/partnerServices';
import templateExcelServices from 'src/services/templateExcelServices';
import { showMessage } from 'src/utils';
import { currDate } from 'src/utils/dateUtils';
import PartnerInfo from './PartnerInfo';
import PartnerServiceAreaInfo from './PartnerServiceAreaInfo';
import Section from '../../components/container/Section';
import PartnerDrawer from './drawer/PartnerDrawer';

interface IParams {
    id: string;
}

const PartnerView: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id = Number(props.match.params.id);
    const history = useHistory();
    const [partner, setPartner] = useState<Partner>({} as Partner)

    useEffect(() => {
        if (id !== undefined && id !== 0) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        const res = await partnerServices.getById(id);
        setPartner(res.data)
    }

    return (
        <div>
            <PageHeader title="กำหนดพาร์ทเนอร์">
                <Button
                    size="large"
                    style={{ width: 120 }}
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.goBack()}
                >
                    กลับ
                </Button>
            </PageHeader>

            <Row gutter={16}>
                <Col span={6}>
                    <PartnerInfo partner={partner} refresh={fetchData()} />
                </Col>
                <Col span={18}>
                    <PartnerServiceAreaInfo partner={partner} />
                </Col>
            </Row>
        </div>
    )
}

export default PartnerView
