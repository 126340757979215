import http from '../lib/http';
import { servicePort } from './configServices';

const url = servicePort + '/partner';

const getById = (id: number) => {
    return http.get(`${url}/byId/${id}`);
}

const getByPartnerName = (name: string) => {
    const param = `name=${name}`;
    return http.get(url + "/byPartnerName/?" + param);
}

const addPartner = (entity: any) => {
    return http.post(url + "/add", entity);
};

const updatePartner = (entity: any) => {
    return http.put(url + "/update", entity);
};

const isUsedPartnerCode = (partnerCode: string) => {
    const param = `partnerCode=${partnerCode}`;
    return http.get(url + "/isUsedPartnerCode/?" + param);
}

const isUsedTaxId = (taxId: string) => {
    const param = `taxId=${taxId}`;
    return http.get(url + "/isUsedTaxId/?" + param);
}

export default { getById, getByPartnerName, addPartner, updatePartner, isUsedPartnerCode, isUsedTaxId }
