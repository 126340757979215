import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Radio, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import locationServices from 'src/services/locationServices';
import partnerServices from 'src/services/partnerServices';
import prenameServices from 'src/services/prenameServices';
import { SessionUser } from 'src/store/authen/types';
import { v4 as uuidv4 } from 'uuid';
import { LocationView } from "../../../domain/locationViewType";
import { PreName } from "../../../domain/preNameType";
import { handleEnter, showMessage } from "../../../utils";

const { Option } = Select;

interface IProps {
    partner: any;
    saveComplete?: any;
    onCloseDrawer?: any;
}

const PartnerDrawer: React.FC<IProps> = (props) => {
    const { partner, saveComplete, onCloseDrawer } = props;
    const [isLoad, setLoad] = useState(false);
    const [form] = Form.useForm();
    const id = partner != undefined ? partner.id : 0; // 0 = ADD, !0 = EDIT
    const [prenames, setPrenames] = useState<PreName[]>([]);
    const [locations, setLocations] = useState<LocationView[]>([]);
    const sessionUser: SessionUser = useSelector((state: any) => state.auth.sessionUser);

    useEffect(() => {
        if (isLoad) return;
        // console.log(id, partner)
        getAllLocations();
        getAllPrenames();
        if (id === 0) {
            genUuid()
        } else {
            setPartnerData(partner);
        }
        setLoad(true)
    }, []);

    async function genUuid() {
        const random = uuidv4();
        const uuid = 'PTN' + random.substring(23);
        const res = await partnerServices.isUsedPartnerCode(uuid);
        if (res.data === false) {
            form.setFieldValue('partnerCode', uuid)
        }
    }

    const getAllLocations = async () => {
        const res = await locationServices.getAll();
        setLocations(res.data);
    }

    const getAllPrenames = async () => {
        const res = await prenameServices.getAll();
        setPrenames(res.data);
    }

    async function setPartnerData(partner: any) {
        // console.log(partner)
        const prename = await prenameServices.getById(partner.prenameId);
        form.setFieldsValue({
            'partnerCode': partner.partnerCode,
            'prenameId': partner.prenameId,
            'prename': prename.data.prename,
            'partnerName': partner.partnerName,
            'taxId': partner.taxId,
            'address': partner.address,
            'addrPath': partner.addrPath,
            'locationCode': partner.locationCode,
            'zipCode': partner.zipcode,
            'partnerTel': partner.partnerTel,
            'useFlg': partner.useFlg,
        })
    }

    const onSelectPrename = async (id: any) => {
        const selectedPrename = prenames.find(obj => obj.id === id);
        if (selectedPrename === undefined) {
            message.error("เลือกคำนำหน้าไม่ถูกต้อง!");
            form.setFieldValue('prename', null);
            return;
        } else {
            form.setFieldsValue({
                'prenameId': selectedPrename.id,
                'prename': selectedPrename.prename,
            })
        }
    };

    const onSelectLocation = async (id: any) => {
        const selectedLocation = locations.find(obj => obj.id === id);
        if (selectedLocation === undefined) {
            message.error("เลือกที่อยู่ไม่ถูกต้อง!");
            form.setFieldValue('addrPath', null);
            return;
        } else {
            form.setFieldsValue({
                'addrPath': selectedLocation.addrPath,
                'locationCode': selectedLocation.tambonCode,
                'zipCode': selectedLocation.zipcode
            })
        }
    };

    const onFinish = async (values: any) => {
        const data = {
            id: id,
            partnerCode: values.partnerCode,
            prenameId: values.prenameId,
            partnerName: values.partnerName,
            address: values.address,
            addrPath: values.addrPath,
            locationCode: values.locationCode,
            zipcode: values.zipCode,
            taxId: values.taxId,
            partnerTel: values.partnerTel,
            useFlg: values.useFlg,
            savStation: sessionUser.stationCode,
            createUserid: sessionUser.userId,
            createUsername: sessionUser.username
        };

        console.log("dataToSave ", data);

        if (id === 0) {
            try {
                partnerServices.addPartner(data).then(() => {
                    message.success("Save Complete", 1.5);
                    saveComplete();
                }).catch((err: any) => {
                    console.log(err);
                });
            } catch (err) {
                showMessage('' + err);
            }
        }

        if (id !== 0) {
            partnerServices.updatePartner(data).then(() => {
                message.success("Update Complete", 1.5);
                saveComplete();
            }).catch(err => {
                console.log(err)
            });

        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
        message.error("บันทึกข้อมูลไม่สำเร็จ : " + errorInfo);
    };

    return (
        <>
            <Form
                form={form}
                initialValues={{ remember: true }}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={[4, 32]}>
                    <Col span={24} >
                        <Form.Item name="partnerCode" label="รหัส">
                            <Input placeholder="รหัสพาร์ทเนอร์" disabled />
                        </Form.Item>

                        <Form.Item name="prenameId" style={{ display: 'none' }} />

                        <Form.Item label="คำนำหน้า" name="prename"
                            rules={[{ required: true, message: "Required : กรุณาคำเลือกนำหน้า" }]}
                        >
                            <Select
                                showSearch
                                placeholder="เลือกคำนำหน้า"
                                optionFilterProp="children"
                                filterOption={true}
                                onSelect={onSelectPrename}
                                onKeyDown={handleEnter}
                            >
                                {prenames.map((obj: any) => {
                                    return <Option value={obj.id}>{obj.prename}</Option>;
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item name="partnerName" label="ชื่อ"
                            rules={[{ required: true, message: "กรุณาระบุชื่อพาร์ทเนอร์" }]}
                        >
                            <Input placeholder="ระบุชื่อพาร์ทเนอร์" onKeyDown={handleEnter} />
                        </Form.Item>

                        <Form.Item
                            label="เลขประจำตัวผู้เสียภาษี"
                            name="taxId"
                            rules={[
                                { required: true, message: "กรุณาระบุเลขประจำตัวผู้เสียภาษี" },
                                { max: 13, message: "เลขประจำตัวผู้เสียภาษี 13 หลัก" },
                                { min: 13, message: "เลขประจำตัวผู้เสียภาษี 13 หลัก" },
                                {
                                    async validator(_, value) {
                                        async function chkTaxId(taxid: string) {
                                            const res = await partnerServices.isUsedTaxId(taxid);
                                            if (res.status === 200) return res.data;
                                            return true;
                                        }
                                        if (value.length >= 13) {
                                            if (id === 0) {
                                                if (await chkTaxId(value) === false) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('เลขประจำตัวผู้เสียภาษีซ้ำ!'));
                                            } else {
                                                if (partner !== undefined && value !== partner.taxId) {
                                                    if (await chkTaxId(value) === false) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('เลขประจำตัวผู้เสียภาษีซ้ำ!'));
                                                }

                                            }
                                        }
                                    },
                                }
                            ]}
                        >
                            <Input placeholder="ระบุเลขประจำตัวผู้เสียภาษี" maxLength={13} onKeyDown={handleEnter} />
                        </Form.Item>

                        <Form.Item label="เลขที่" name="address" rules={[{ required: true, message: "กรุณาระบุบ้าน ซอย ถนน" }]}>
                            <Input placeholder="ระบุบ้าน ซอย ถนน" onKeyDown={handleEnter} />
                        </Form.Item>

                        <Form.Item
                            label="ที่อยู่"
                            name="addrPath"
                            rules={[{ required: true, message: "กรุณาค้นหาตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์" }]}
                        >
                            <Select
                                showSearch
                                placeholder="ค้นหาตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
                                optionFilterProp="children"
                                filterOption={true}
                                onSelect={onSelectLocation}
                                onKeyDown={handleEnter}
                            >
                                {locations.map((obj: any) => {
                                    return <Option value={obj.id}>{obj.addrPath}</Option>;
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item name="locationCode" style={{ display: 'none' }} />
                        <Form.Item name="zipCode" style={{ display: 'none' }} />

                        <Form.Item
                            label="เบอร์โทรศัพท์"
                            name="partnerTel"
                            rules={[{ required: true, message: "กรุณาระบุเบอร์โทรศัพท์" }]}
                        >
                            <Input placeholder="ระบุเบอร์โทรศัพท์" maxLength={10} />
                        </Form.Item>

                        <Form.Item name="useFlg" label="สถานะ">
                            <Radio.Group defaultValue={0}>
                                <Radio value={0}>ใช้งาน</Radio>
                                <Radio value={-1}>ไม่ใช้งาน</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Space style={{ width: '100%', justifyContent: 'center' }} size={'large'} >
                            <Button type="primary" icon={<SaveOutlined />}
                                onClick={() => form.submit()}
                            >
                                บันทึก
                            </Button>
                            <Button icon={<CloseCircleOutlined />} onClick={onCloseDrawer}>
                                กลับ
                            </Button>
                        </Space>

                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default PartnerDrawer
